import React, { useEffect } from 'react';
import Header from '../mainComponents/Header';
import Main from '../mainComponents/Main';

const Layout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='dark:bg-slate-950 dark:text-white'>
      <Header />
      <Main />
    </div>
  );
};

export default Layout;

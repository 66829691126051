import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getSingleProject } from '../lib/actions';
import { Project } from '../lib/definitions';
import Loader from './Loader';

const SingleProject = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const identifier = params.slug as string;
  const navigate = useNavigate();
  console.log('identifier: ' + identifier);

  const fetchSingleProject = async () => {
    try {
      const project = await getSingleProject(identifier);
      if (project) {
        setProject(project.data as Project);
      }
    } catch (error) {
      console.log('error: ' + error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSingleProject();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const image = require(`../assets/${project?.image}`);

  return (
    <div className='page-wrapper'>
      <div className='flex justify-end'>
        <button
          className='py-2 px-4 border hover:bg-slate-200 dark:hover:bg-transparent'
          onClick={() => navigate(-1)}
        >
          &larr; Go Back
        </button>
      </div>
      <h2 className='text-2xl mb-4'>{project?.title}</h2>
      <figure className='max-w-[500px] h-full border p-4'>
        <img
          className='w-full h-full border-2 border-white'
          src={image}
          alt={project?.title}
        />
      </figure>
      <div className='my-8'>
        {project?.notes && (
          <small className='inline-block p-2 mb-4 text-sm bg-yellow-500 rounded-md text-slate-800'>
            NOTE: {project?.notes}
          </small>
        )}
        <p>{project?.description}</p>
      </div>

      <div className='mb-10'>
        <h2 className='mb-3 page-subheader'>Tech Stack</h2>
        <div className='flex flex-wrap items-center justify-start gap-4'>
          {project?.stack.map((item) => {
            return <span className='py-2 px-4 border'>{item}</span>;
          })}
        </div>
      </div>

      <div className='mb-10'>
        <h2 className='mb-3 page-subheader'>Features</h2>
        <div className='flex items-center justify-start gap-4'>
          <ol>
            {project?.features?.map((feature) => {
              return (
                <li className='mb-2 list-decimal list-inside'>{feature}</li>
              );
            })}
          </ol>
        </div>
      </div>

      <div className='flex gap-4'>
        <Link
          className='p-2 px-4 border hover:bg-slate-200 dark:hover:bg-transparent'
          to={project?.live ? project?.live : '/'}
          target='_blank noreferer'
        >
          View Page
        </Link>
        <Link
          className='p-2 px-4 border hover:bg-slate-200 dark:hover:bg-transparent'
          to={project?.github ? project?.github : '/'}
          target='_blank noreferer'
        >
          View Code
        </Link>
      </div>
    </div>
  );
};

export default SingleProject;

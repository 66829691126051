import { getDocs, collection, orderBy, query } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';

// Get all projects from Firestore
export const getAllProjects = async () => {
  try {
    const snapshot = await getDocs(
      query(collection(firestore, 'projects'), orderBy('featured', 'desc'))
    );
    const data = snapshot.docs.map((doc) => doc.data());
    return { data };
  } catch (error) {
    console.log('error: ' + error);
  }
};

export const getSingleProject = async (slug: string) => {
  try {
    const snapshot = await getDocs(collection(firestore, 'projects'));
    const data = snapshot.docs.map((doc) => doc.data());
    const selectedData = data.find((project) => project.slug === slug);
    return { data: selectedData };
  } catch (error) {
    console.log('error: ' + error);
  }
};

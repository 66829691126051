import React from 'react';
import { Link } from 'react-router-dom';

const NavLogo = () => {
  return (
    <div className='animate-in text-3xl pl-6'>
      <Link to='/' className='text-slate-700 dark:text-white font-bold'>
        VIJAY
      </Link>
    </div>
  );
};

export default NavLogo;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import NavLogo from '../childComponents/NavLogo';

const Header = () => {
  const [showNav, setShowNav] = useState(false);

  return (
    <header className='flex justify-between items-center border-b'>
      <NavLogo />
      <nav className='hidden lg:flex '>
        <NavLink
          className={`${({ isActive }: { isActive: boolean }) =>
            isActive ? 'font-bold' : ''} p-6`}
          to='/'
          onClick={() => setShowNav(false)}
        >
          Home
        </NavLink>

        <NavLink
          className={`${({ isActive }: { isActive: boolean }) =>
            isActive ? 'font-bold' : ''} p-6`}
          to='/projects'
        >
          Projects
        </NavLink>

        <NavLink
          className={`${({ isActive }: { isActive: boolean }) =>
            isActive ? 'font-bold' : ''} p-6`}
          to='/contact'
        >
          Contact
        </NavLink>

        <NavLink
          className={`${({ isActive }: { isActive: boolean }) =>
            isActive && 'text-red-500'} p-6`}
          to='/resume'
        >
          Resume
        </NavLink>
      </nav>

      <div className='lg:hidden flex gap-6 p-4'>
        <button
          onClick={() => setShowNav(!showNav)}
          className='material-icons align-middle text-3xl'
        >
          menu
        </button>
      </div>
      {
        <nav
          className={`relative lg:hidden mobile-nav p-6 ${
            showNav ? ' show' : ''
          }`}
        >
          <div className='absolute top-6 right-6'>
            <button
              className='material-icons text-3xl'
              onClick={() => setShowNav(!showNav)}
            >
              close
            </button>
          </div>

          <div className='pt-8'>
            <NavLink
              className='class-mob-nav'
              onClick={() => setShowNav(false)}
              to='/'
            >
              Home
            </NavLink>

            <NavLink
              className='class-mob-nav'
              onClick={() => setShowNav(false)}
              to='/projects'
            >
              Projects
            </NavLink>

            <NavLink
              className='class-mob-nav'
              onClick={() => setShowNav(false)}
              to='/contact'
            >
              Contact
            </NavLink>

            <NavLink
              className='class-mob-nav'
              onClick={() => setShowNav(false)}
              to='/resume'
            >
              Resume
            </NavLink>
          </div>
        </nav>
      }
    </header>
  );
};

export default Header;

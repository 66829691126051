import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import { getAllProjects } from '../lib/actions';
import { Project } from '../lib/definitions';
import Loader from './Loader';

const Projects = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchProjects = async () => {
    try {
      const allProjects = await getAllProjects();
      if (allProjects) {
        setProjects(allProjects.data as Project[]);
      }
    } catch (error) {
      console.log('error: ' + error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='page-wrapper animate-in'>
      <div className='grid-autofit border-t border-l'>
        {projects.map((project) => (
          <ProjectCard key={project.id} {...project} />
        ))}
      </div>
    </div>
  );
};

export default Projects;

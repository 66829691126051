import linkedin from '../assets/icons/linkedin.png';
import github from '../assets/icons/github.png';
import { Link } from 'react-router-dom';

const SocialIcons = () => {
  return (
    <div className='flex items-center gap-4'>
      <Link to='https://www.linkedin.com/in/vijaykc' target='_blank noreferer'>
        <img
          className='w-6 transition-all dark:invert hover:scale-105'
          src={linkedin}
          alt='linkedin icon'
        />
      </Link>

      <Link to='https://www.github.com/kcvijay' target='_blank noreferer'>
        <img
          className='w-6 transition-all dark:invert hover:scale-105'
          src={github}
          alt='github icon'
        />
      </Link>
    </div>
  );
};

export default SocialIcons;

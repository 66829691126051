import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Homepage from './mainComponents/Homepage';
import Projects from './childComponents/Projects';
import ContactForm from './childComponents/ContactForm';
import Resume from './childComponents/Resume';
import SingleProject from './childComponents/SingleProject';
import NotFound from './childComponents/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/projects/:slug' element={<SingleProject />} />
          <Route path='/contact' element={<ContactForm />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import { Project } from '../lib/definitions';

const ProjectCard = (props: Project) => {
  const projectImage = require(`../assets/${props.image}`);
  return (
    <Link
      className='hover:scale-105 hover:z-50 hover:bg-white dark:hover:bg-slate-950 transition-all'
      to={`/projects/${props.slug}`}
    >
      <div className='project-card hover:border-l hover:border-t p-6'>
        <figure className='relative w-full h-[200px] border overflow-hidden'>
          <img
            className='w-full h-full object-cover grayscale hover:grayscale-0 transition-all'
            src={projectImage}
            alt={props.title}
          />
          {props.featured && <span className='featured-card'>Featured</span>}
        </figure>
        <h2 className='mt-3 text-xl'>{props.title}</h2>
        <div className='line-clamp-2 h-12 mt-2'>
          <p className='dark:text-slate-300 text-slate-500'>
            {props.description}
          </p>
        </div>
        <div className='mt-2 text-sm text-slate-500 h-12'>
          <p className='font-bold'>Stack</p>
          {props.stack.join(', ')}
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;

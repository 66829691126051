import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import linkedin from '../assets/icons/linkedin.png';
import github from '../assets/icons/github.png';
import profile from '../assets/profile-pic.jpg';

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='animate-in page-wrapper flex items-center justify-center min-h-[90vh] px-2 overflow-auto'>
      <div>
        <div className='flex flex-col gap-8'>
          <div className='flex flex-col gap-4'>
            <section className='grid md:grid-cols-2 gap-1'>
              <div className='border p-4'>
                <div>
                  <img
                    className='w-24 border'
                    src={profile}
                    alt='profile pic'
                  />

                  <p className='text-3xl mt-4'>Vijay K.C.</p>
                  <p className='text-2xl text-slate-500'>
                    Full-stack Web Developer
                  </p>
                </div>

                <p className='flex flex-wrap text-sm mt-4 gap-1 text-slate-500'>
                  <span className='border p-1'>React.js</span>
                  <span className='border p-1'>Next.js</span>
                  <span className='border p-1'>Node.js</span>
                  <span className='border p-1'>Express.js</span>
                  <span className='border p-1'>TypeScript</span>
                  <span className='border p-1'>JavaScript</span>
                  <span className='border p-1'>MongoDB</span>
                  <span className='border p-1'>PostgreSQL</span>
                  <span className='border p-1'>and more...</span>
                </p>
              </div>
              <div className='transition-all border p-4 bg-slate-50 dark:bg-transparent'>
                <p className='leading-10 tracking-wide'>
                  Fascinated by the expansiveness of web development and innate
                  interest in UI/UX, I share a passion in web development and
                  design. Using powerful programming tools as JavaScript and
                  TypeScript, UI and CSS libraries like React, Material UI and,
                  Tailwind CSS, I create responsive and visually appealing web
                  solutions for every purposes. Press the buttons below to
                  navigate more information about me.
                </p>
                <div className='flex gap-2 items-start mt-4'>
                  <Link
                    className='py-2 px-4 border hover:bg-slate-200 dark:hover:bg-transparent'
                    to='/projects/namify-pizzaria'
                  >
                    Featured Project
                  </Link>
                  <Link
                    className='py-2 px-4 border hover:bg-slate-200 dark:hover:bg-transparent'
                    to='/resume'
                  >
                    Resume
                  </Link>
                </div>
              </div>
            </section>
          </div>
          <div className='flex flex-col gap-12'>
            <div className='flex items-center justify-center gap-6'>
              <Link
                to='https://www.linkedin.com/in/vijaykc'
                target='_blank noreferer'
              >
                <img
                  className='w-[24px] transition-all hover:scale-105 dark:invert'
                  src={linkedin}
                  alt='linkedin icon'
                />
              </Link>

              <Link
                to='https://www.github.com/kcvijay'
                target='_blank noreferer'
              >
                <img
                  className='w-[24px] transition-all hover:scale-105 dark:invert'
                  src={github}
                  alt='github icon'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;

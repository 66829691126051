import React from 'react';

const Loader = () => {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <span className='w-16 h-16 border-8 border-b-transparent border-slate-300 dark:border-slate-100 dark:border-b-transparent animate-spin rounded-full'></span>
    </div>
  );
};

export default Loader;

export const education = [
  {
    id: 1,
    institution: 'Business College Helsinki',
    degree: 'Full-stack Web Developer (React & Node)',
    duration: 'August 2022 - December 2023',
    location: 'Helsinki, Finland',
    courses: [
      'React',
      'Node.js',
      'Express.js',
      'MongoDB',
      'MySQL',
      'TypeScript',
      'JavaScript',
      'HTML5',
      'CSS3',
      'Figma',
      'Git',
      'GitHub',
      'Docker',
      'Kubernetes',
      'VS Code',
      'Postman',
      'Trello',
      'AWS CI/CD',
    ],
  },
  {
    id: 2,
    institution: 'Centria UAS, Kokkola',
    degree: 'Bachelors in Business Administration',
    duration: 'August 2012 - November 2015',
    location: 'Kokkola, Finland',
    courses: [
      'Marketing',
      'Accounting',
      'Entrepreneurship',
      'Business Law',
      'Leadership',
      'Communication',
      'Mathematics',
      'Management',
      'SAP ERP',
    ],
  },
];

export const experiences = [
  {
    id: 1,
    title: 'Frontend Web Developer - Trainee',
    company: 'Unelma Platforms Oy',
    duration: 'September 2023 - December 2023',
    responsibilities: [
      'Successfully completed the 3-months internship as a Frontend Web Developer',
      'Optimized the codebase and improved the performance of the web application',
      'Collaborated with the team of developers',
      'Improved the UI/UX of the web application',
      'Achieved grade "Excellent-5" in the final evaluation',
    ],
  },
  {
    id: 2,
    title: 'Production Operator',
    company: 'Tampereen Tiivisteteollisuus Oy',
    duration: 'August 2019 - January 2022',
    responsibilities: [
      'Safely operated the production machines and equipment',
      'Maintained the production area clean and organized',
      'Collaborated with the team of production operators',
      'Checked the quality of the products and reported the defects',
    ],
  },
  {
    id: 3,
    title: 'Cleaner',
    company: 'Lassila & Tikanoja Oyj',
    duration: 'January 2013 - July 2019',
    responsibilities: [
      'Responsibly cleaned and sanitized the client premises',
      'Trained the new and junior cleaners',
      'Received the great feedback from client and the company',
    ],
  },
];

export const frontendTechs = [
  { id: 1, name: 'HTML5', icon: 'html5' },
  { id: 2, name: 'CSS3', icon: 'css3' },
  { id: 3, name: 'JavaScript', icon: 'javascript' },
  { id: 4, name: 'TypeScript', icon: 'typescript' },
  { id: 5, name: 'React.js', icon: 'react' },
  { id: 6, name: 'Next.js', icon: 'next' },
  { id: 7, name: 'Electron.js', icon: 'electron' },
  { id: 8, name: 'Material UI', icon: 'material-ui' },
  { id: 9, name: 'Tailwind CSS', icon: 'tailwindcss' },
  { id: 10, name: 'Figma', icon: 'figma' },
];

export const backendTechs = [
  { id: 1, name: 'Node.js', icon: 'nodejs' },
  { id: 2, name: 'Express.js', icon: 'express' },
  { id: 3, name: 'MongoDB', icon: 'mongodb' },
  { id: 4, name: 'Mongoose', icon: 'mongoose' },
  { id: 5, name: 'PostgreSQL', icon: 'postgresql' },
  { id: 6, name: 'Firebase', icon: 'firebase' },
  { id: 7, name: 'Supabase', icon: 'supabase' },
  { id: 8, name: 'REST API', icon: 'restapi' },
];

export const otherSkills = [
  { id: 1, name: 'Git', icon: 'git' },
  { id: 2, name: 'GitHub', icon: 'github' },
  { id: 3, name: 'BitBucket', icon: 'gitlab' },
  { id: 4, name: 'Netlify', icon: 'netlify' },
  { id: 5, name: 'Vercel', icon: 'vercel' },
  { id: 7, name: 'Docker', icon: 'docker' },
  { id: 8, name: 'Kubernetes', icon: 'nginx' },
  { id: 9, name: 'VS Code', icon: 'vscode' },
  { id: 10, name: 'Postman', icon: 'postman' },
  { id: 12, name: 'Trello', icon: 'trello' },
  { id: 13, name: 'Jira', icon: 'jira' },
  { id: 14, name: 'Slack', icon: 'slack' },
  { id: 15, name: 'WordPress', icon: 'wordpress' },
  { id: 16, name: 'Microsoft 365', icon: 'microsoft365' },
];

export const languages = [
  {
    id: 1,
    name: 'English 🇬🇧',
    writing: 'Proficient',
    speaking: 'Proficient',
    certificate: true,
  },
  {
    id: 2,
    name: 'Finnish 🇫🇮',
    writing: 'Professional',
    speaking: 'Professional',
    certificate: true,
  },
  {
    id: 3,
    name: 'Hindi 🇮🇳',
    writing: 'Proficient',
    speaking: 'Proficient',
    certificate: false,
  },
  {
    id: 4,
    name: 'Nepali🇳🇵',
    writing: 'Native',
    speaking: 'Native',
    certificate: false,
  },
];

export const interests = [
  { id: 1, name: 'Jogging' },
  { id: 2, name: 'Singing' },
  { id: 3, name: 'Gym' },
  { id: 4, name: 'Discovering new techs' },
];

export const trainings = [
  {
    id: 1,
    name: 'Getting started with DevOps on AWS',
    issued: '2023',
    issuedBy: 'Amazon Web Services',
  },
  {
    id: 2,
    name: 'Introduction to AWS CodeCommit',
    issued: '2023',
    issuedBy: 'Amazon Web Services',
  },
  {
    id: 3,
    name: 'Introduction to Amazon Elastic Compute Cloud (EC2)',
    issued: '2023',
    issuedBy: 'Amazon Web Services',
  },
  {
    id: 4,
    name: 'Pricing in Service Business',
    issued: '2022',
    issuedBy: 'Laurea UAS',
  },
  {
    id: 5,
    name: 'Basics of Payroll',
    issued: '2021',
    issuedBy: 'TREDU',
  },
  {
    id: 6,
    name: 'First-Aid Training',
    issued: '2021',
    issuedBy: 'Finnish Red Cross',
  },
];

import React from 'react';

const NotFound = () => {
  return (
    <div className='w-full mx-auto'>
      <div className='m-12'>
        <p className='text-3xl text-slate-600'>404</p>
        <p>Page not found !</p>
      </div>
    </div>
  );
};

export default NotFound;

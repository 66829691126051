import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, ValidationError } from '@formspree/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface EmailData {
  emailAddress: string;
  subject: string;
  message: string;
}
const Form = () => {
  const [state, handleSubmit] = useForm('xjvqnlqk');

  const [emailData, setEmailData] = useState<EmailData>({
    emailAddress: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmailData({ ...emailData, [e.target.name]: e.target.value });
  };

  if (state.succeeded) {
    toast.success('Your message has been sent successfully.', {
      autoClose: 3000,
    });
    setTimeout(() => window.location.reload(), 3000);
  }

  return (
    <form onSubmit={handleSubmit} className='flex flex-col'>
      <ToastContainer />
      <div className='flex gap-4 items-center'>
        <label className='inline-block w-24' htmlFor='emailAddress'>
          Email
        </label>
        <input
          className='grow p-3 border bg-transparent rounded-none'
          type='email'
          id='emailAddress'
          name='emailAddress'
          placeholder='email@website.com'
          autoComplete='off'
          spellCheck='false'
          required
          onChange={handleInputChange}
        />
        <ValidationError prefix='Email' field='email' errors={state.errors} />
      </div>
      <div className='flex gap-4 items-center'>
        <label className='inline-block w-24' htmlFor='subject'>
          Subject
        </label>
        <input
          className='grow p-3 border border-t-0 bg-transparent rounded-none'
          type='text'
          id='subject'
          name='subject'
          placeholder='Inquiry'
          autoComplete='off'
          spellCheck='false'
          required
          onChange={handleInputChange}
        />
        <ValidationError
          prefix='Subject'
          field='subject'
          errors={state.errors}
        />
      </div>
      <div className='flex gap-4 items-center'>
        <label className='inline-block w-24' htmlFor='message'>
          Message
        </label>
        <textarea
          className='grow h-32 p-3 border border-t-0 bg-transparent rounded-none'
          id='message'
          name='message'
          maxLength={500}
          minLength={20}
          placeholder='Max 500 characters'
          autoComplete='off'
          spellCheck='false'
          required
          onChange={handleInputChange}
        />
        <ValidationError
          prefix='Message'
          field='message'
          errors={state.errors}
        />
      </div>
      <div className='flex gap-4 items-center'>
        <div className='w-full'></div>
        <div className='flex w-full'>
          <button
            type='submit'
            className='grow border border-t-0 border-r-0 p-3 hover:bg-slate-200 dark:hover:bg-transparent'
            disabled={state.submitting}
          >
            {state.submitting ? 'Sending...' : 'Send'}
          </button>
          <button
            type='reset'
            className='grow border border-t-0 p-3 hover:bg-slate-200 dark:hover:bg-transparent'
          >
            Reset
          </button>
        </div>
      </div>
      <p className='pt-8 text-sm text-slate-500 text-center'>
        *Powered by{' '}
        <Link
          className='hover:underline'
          target='_blank noreferer'
          to='https://formspree.io'
        >
          Formspree
        </Link>
      </p>
    </form>
  );
};

export default Form;

import React from 'react';
import Form from './Form';
const ContactForm = () => {
  return (
    <div className='page-wrapper animate-in'>
      <div className='max-w-[600px] h-screen mx-auto'>
        <h2 className='text-xl mb-8'>Send a Message</h2>
        <Form />
      </div>
    </div>
  );
};

export default ContactForm;

import profile from '../assets/profilepic.png';
import {
  backendTechs,
  education,
  experiences,
  frontendTechs,
  interests,
  languages,
  otherSkills,
  trainings,
} from '../lib/lists';
import SocialIcons from './SocialIcons';
import clsx from 'clsx';

const Resume = () => {
  return (
    <div className='animate-in p-4 min-h-screen'>
      <section className='grid md:grid-cols-2 gap-1'>
        <figure className='p-4 border'>
          <h2 className='text-xl mb-4'>Profile</h2>
          <img className='w-28' src={profile} alt='profile' />
          <figcaption className='my-2'>
            <p className='text-lg'>Vijay K.C.</p>
            <p className='text-slate-500'>Full-stack Web Developer</p>
            <small className='text-slate-500'>
              React.js, Next.js, TypeScript, Node.js
            </small>
          </figcaption>
          <SocialIcons />
        </figure>
        <div className='p-4 border'>
          <p className='tracking-wide leading-8'>
            Driven by a sincere love for web development which was sparked by my
            early fascination with computers, I took risks in my 30s to achieve
            extensive skills in the field. As a result of months of self-study,
            I got enrolled in Fullstack Web Development course at Business
            College Helsinki where I specialized to be a Full-stack web
            developer primarily in MERN stack. <br />I believe in continuous
            education. The field of web development is growing swiftly, with
            numerous languages, libraries and frameworks being released in a
            short span of time. I am targeting to learn fundamentals first
            because I believe that the strong base of fundaments is highly
            necessary to overcome any libraries and frameworks.
          </p>
        </div>

        <div className='p-4 border'>
          <h2 className='text-xl text-center mb-4'>Education</h2>
          <Education />
        </div>
        <div className='p-4 border'>
          <h2 className='text-xl mb-4 text-center'>Experiences</h2>
          <Experience />
        </div>
        <div className='p-4 border'>
          <h2 className='text-xl mb-4 text-center'>Frontend</h2>
          <FrontendTechs />
        </div>
        <div className='p-4 border'>
          <h2 className='text-xl mb-4 text-center'>Backend</h2>
          <BackendTechs />
        </div>
        <div className='p-4 border'>
          <h2 className='text-xl mb-4 text-center'>Other Skills</h2>
          <OtherSkills />
        </div>
        <div className='p-4 border'>
          <h2 className='text-xl mb-4 text-center'>Trainings & Courses</h2>
          <OtherTrainings />
        </div>
        <div className='p-4 border'>
          <h2 className='text-xl mb-4 text-center'>Languages</h2>
          <Languages />
        </div>

        <div className='p-4 border'>
          <h2 className='text-xl mb-4 text-center'>Interests</h2>
          <Interests />
        </div>
      </section>
    </div>
  );
};

const FrontendTechs = () => {
  return (
    <ul className='grid lg:grid-cols-3 md:grid-cols-2  gap-1'>
      {frontendTechs.map((tech) => (
        <li
          key={tech.id}
          className='p-2 shadow dark:border text-center odd:bg-slate-50 dark:odd:bg-transparent dark:bg-transparent'
        >
          {tech.name}
        </li>
      ))}
    </ul>
  );
};

const BackendTechs = () => {
  return (
    <ul className='grid lg:grid-cols-3 md:grid-cols-2 gap-1'>
      {backendTechs.map((tech) => (
        <li
          key={tech.id}
          className='p-2 shadow dark:border text-center odd:bg-slate-50 dark:odd:bg-transparent dark:bg-transparent'
        >
          {tech.name}
        </li>
      ))}
    </ul>
  );
};

const Education = () => {
  return (
    <div>
      {education.map((edu, i) => (
        <div key={edu.id} className={clsx({ 'mt-4': i !== 0 })}>
          <p>{edu.degree}</p>
          <p className='text-slate-500'>{edu.institution}</p>
          <small className='text-slate-500'>{edu.duration}</small>

          <details className='text-sm dark:text-slate-300 text-slate-500'>
            <summary className='mt-2 cursor-pointer hover:text-slate-500'>
              Courses
            </summary>
            <ul className='grid grid-cols-3 lg:grid-cols-4 gap-1 mt-1'>
              {edu.courses.map((course, i) => (
                <li key={i}>{course}</li>
              ))}
            </ul>
          </details>
        </div>
      ))}
    </div>
  );
};
const Experience = () => {
  return (
    <div>
      {experiences.map((exp, i) => (
        <div key={exp.id} className={clsx({ 'mt-4': i !== 0 })}>
          <p>{exp.title}</p>
          <p className='text-slate-500'>{exp.company}</p>
          <small className='text-slate-500'>{exp.duration}</small>

          <details className='text-sm dark:text-slate-300 text-slate-500'>
            <summary className='mt-2 cursor-pointer hover:text-slate-500'>
              Achievements
            </summary>
            <ul className='list-decimal list-inside ml-2 mt-1'>
              {exp.responsibilities.map((resp, i) => (
                <li key={i}>{resp}</li>
              ))}
            </ul>
          </details>
        </div>
      ))}
    </div>
  );
};

const OtherSkills = () => {
  return (
    <ul className='grid lg:grid-cols-3 md:grid-cols-2 gap-1'>
      {otherSkills.map((skill) => (
        <li
          key={skill.id}
          className='p-2 shadow dark:border text-center odd:bg-slate-50 dark:odd:bg-transparent dark:bg-transparent'
        >
          {skill.name}
        </li>
      ))}
    </ul>
  );
};

const Languages = () => {
  return (
    <ul className='grid md:grid-cols-2 gap-1'>
      {languages.map((lang) => (
        <li
          key={lang.id}
          className='relative p-2 shadow dark:border flex flex-col gap-1'
        >
          <span className='text-lg'>{lang.name}</span>
          <span className='text-sm'>Speaking: {lang.speaking}</span>
          <span className='text-sm'>Writing: {lang.speaking}</span>
          {lang.certificate && (
            <span className='absolute top-2 right-2 text-[12px] text-green-700 py-1 px-2 border border-green-300 bg-green-200'>
              Certified
            </span>
          )}
        </li>
      ))}
    </ul>
  );
};

const Interests = () => {
  return (
    <ul className='grid lg:grid-cols-2 gap-1'>
      {interests.map((interest, i) => (
        <li
          key={interest.id}
          className='p-2 shadow dark:border text-center odd:bg-slate-50 dark:odd:bg-transparent dark:bg-transparent'
        >
          {interest.name}
        </li>
      ))}
    </ul>
  );
};

const OtherTrainings = () => {
  return (
    <div className='grid lg:grid-cols-2 gap-1'>
      {trainings.map((training, i) => (
        <div className='shadow dark:border p-2' key={training.id}>
          <p>{training.name}</p>
          <p className='text-slate-500'>{training.issued}</p>
          <small className='text-slate-500'>{training.issuedBy}</small>
        </div>
      ))}
    </div>
  );
};

export default Resume;
